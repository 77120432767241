
<style scoped lang="less">
.Student-GraduationEdit {
  background: #fff;
  box-shadow: @shadow;
  padding: 20px;
  .stu-module-title {
    padding-bottom: 20px;
  }
  .stu_graduation_row {
    .el-col {
      text-align: center;
    }
    // 头像
    .stu_gra_avatar_col {
      border: 1px solid #f0f0f0;
      border-bottom: 0;
      box-sizing: border-box;
      height: 100%;
      .stu_gra_a_col_div {
        padding: 10px;
        .stu_gra_avatar {
          height: 182px;
          width: 80%;
          max-width: 150px;
          // min-width: 120px;
          display: block;
          margin: 0 auto;
        }
        .el-button {
          margin-top: 20px;
        }
      }
      .stu_gra_a_col_col {
        border-top: 1px solid #f0f0f0;
        height: 51px;
      }
    }
    .item {
      display: flex;
      p {
        // padding: 12px;
        line-height: 50px;
        height: 50px;
        border: 1px solid #f0f0f0;
        margin: 0px -1px -1px 0px;
      }
      @labelWidth: 140px;
      .label {
        background: #fafafa;
        max-width: @labelWidth;
        min-width: @labelWidth;
      }
      .value {
        width: calc(100% - @labelWidth);
      }
      .labels,
      .values {
        height: unset;
        line-height: unset;
      }
      .labels {
        display: flex;
        align-items: center;
        background: #fafafa;
        padding: 0 10px;
        max-width: @labelWidth - 20px;
        min-width: @labelWidth - 20px;
        border: 1px solid #f0f0f0;
        border-right: 0;
      }
      .values {
        width: calc(100% - @labelWidth);
        .values_label {
          display: flex;
          align-items: center;
          div {
            flex: 0.25;
            background: #fafafa;
            padding: 8px 0;
            border-right: 1px solid #f0f0f0;
            border-bottom: 1px solid #f0f0f0;
          }
          div:last-child {
            border-right: 0;
          }
        }
        .values_value {
          display: flex;
          align-items: center;
          li {
            flex: 0.25;
            border-right: 1px solid #f0f0f0;
            border-bottom: 1px solid #f0f0f0;
          }
          li:last-child {
            border-right: 0;
          }
        }
        .values_value:last-child {
          li {
            border-bottom: 0;
          }
        }

        .values_labels_flex_2 {
          div,
          li {
            flex: 0.5;
          }
        }
      }
    }
    .border_null {
      p {
        border-bottom: 0;
      }
    }
  }
}
</style>
<style lang="less">
.Student-GraduationEdit {
  .stu_graduation_row {
    .item .value,
    .item .values {
      .el-input {
        .el-input__inner {
          border: 0;
          cursor: pointer;
        }
      }
      .el-date-editor.el-input__inner {
        border: 0;
        cursor: pointer;
      }
      .el-textarea {
        .el-textarea__inner {
          border: 0;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
<template>
  <!-- 毕业填报 -->
  <div class="Student-GraduationEdit" v-loading="loading">
    <div class="stu-module-title">毕业填报</div>
    <el-row class="stu_graduation_row">
      <el-col :span="18">
        <el-col :xs="24" :sm="24" :md="24" class="item">
          <p class="label">层次</p>
          <p class="value">{{ basic.stageName }}</p>
        </el-col>
        <el-col :xs="24" :sm="24" :md="24" class="item">
          <p class="label">专业</p>
          <p class="value">{{ basic.profName }}</p>
        </el-col>
        <el-col :xs="24" :sm="24" :md="24" class="item">
          <p class="label">学号</p>
          <p class="value">{{ basic.studentNo }}</p>
        </el-col>
        <el-col :xs="24" :sm="24" :md="24" class="item">
          <p class="label">姓名</p>
          <p class="value">
            <el-input
              v-model="items.name"
              clearable
              disabled
              placeholder="请输入姓名"
            ></el-input>
          </p>
        </el-col>
        <el-col :xs="24" :sm="24" :md="24" class="item">
          <p class="label">性别</p>
          <p class="value">
            <el-select v-model="items.sex" clearable>
              <el-option value="0" label="男"></el-option>
              <el-option value="1" label="女"></el-option>
            </el-select>
          </p>
        </el-col>
        <el-col :xs="24" :sm="24" :md="24" class="item">
          <p class="label">民族</p>
          <p class="value">
            <el-input v-model="items.clan" placeholder="请输入民族" clearable></el-input>
          </p>
        </el-col>
        <el-col :xs="24" :sm="24" :md="24" class="item">
          <p class="label">出生日期</p>
          <p class="value">
            <el-date-picker
              v-model="items.birthday"
              format="yyyy/MM/dd"
              value-format="yyyy/MM/dd"
              type="date"
              placeholder="选择日期"
            >
            </el-date-picker>
          </p>
        </el-col>
        <el-col :xs="24" :sm="24" :md="24" class="item">
          <p class="label">证件类型</p>
          <p class="value">
            <el-select v-model="items.certificateType" clearable filterable>
              <el-option
                v-for="(item, index) in cardTypeOptions"
                :key="index"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </p>
        </el-col>
        <el-col :xs="24" :sm="24" :md="24" class="item border_null">
          <p class="label">证件号码</p>
          <p class="value">
            <el-input
              v-model="items.certificateNo"
              placeholder="请输入证件号码"
              clearable
            ></el-input>
          </p>
        </el-col>
      </el-col>
      <el-col :span="6" class="stu_gra_avatar_col">
        <div class="stu_gra_a_col_div">
          <el-avatar shape="square" class="stu_gra_avatar" :src="imageUrl">
            <svg-icon name="graduation"></svg-icon>
          </el-avatar>
          <!-- `${url}hnjxjy-core/eduOnlineQa/addGraduationReport` -->
          <!-- :data="{
              clan: items.clan,
              name: items.name,
              sex: items.sex,
              certificateType: items.certificateType,
              contactAddr: items.contactAddr,
              contactPhone: items.contactPhone,
              postCode: items.postCode,
              jsonFamily: JSON.stringify(
                eduStudentFamilyList.filter((f) => f.memberName)
              ),
              jsonExperience: JSON.stringify(
                eduStudentExperiences.filter((f) => f.experienceInfo)
              ),
              selfIdentify: selfIdentify,
            }"
            :headers="{
              'x-token': auth['x-token'],
              'x-perm': auth['x-perm'],
            }" -->
          <el-upload
            v-if="queryRoot && btnShow === 0"
            class="upload-demo"
            action="#"
            :on-success="handleSuccess"
            :on-error="handleError"
            :on-change="onChange"
            :show-file-list="false"
            :auto-upload="false"
            accept=".jpg,.jpeg,.png,.JPG,.JPEG,.gif"
            ref="upload"
          >
            <el-button size="small" type="primary">上传图片</el-button>
          </el-upload>
        </div>

        <el-col :span="24" class="stu_gra_a_col_col"></el-col>
        <el-col :span="24" class="stu_gra_a_col_col"></el-col>
        <el-col :span="24" class="stu_gra_a_col_col"></el-col>
        <el-col :span="24" class="stu_gra_a_col_col"></el-col>
      </el-col>
    </el-row>

    <el-row class="stu_graduation_row">
      <el-col :xs="24" :sm="24" :md="12" class="item">
        <p class="label">政治面貌</p>
        <p class="value">
          <el-select v-model="items.politicalStatus" clearable filterable>
            <el-option
              v-for="(item, index) in politicalOptions"
              :key="index"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </p>
      </el-col>
      <el-col :xs="24" :sm="24" :md="12" class="item">
        <p class="label">联系电话</p>
        <p class="value">
          <el-input
            v-model="items.contactPhone"
            placeholder="请输入"
            clearable
          ></el-input>
        </p>
      </el-col>
      <el-col :xs="24" :sm="24" :md="12" class="item">
        <p class="label">邮政编码</p>
        <p class="value">
          <el-input
            v-model="items.postCode"
            placeholder="请输入"
            clearable
          ></el-input>
        </p>
      </el-col>
      <el-col :xs="24" :sm="24" :md="12" class="item">
        <p class="label">通信地址</p>
        <p class="value">
          <el-input
            v-model="items.contactAddr"
            placeholder="请输入"
            clearable
          ></el-input>
        </p>
      </el-col>
    </el-row>

    <!-- 家庭主要成员和社会关系 -->
    <el-row class="stu_graduation_row">
      <el-col :span="24" class="item">
        <p class="labels">家庭主要成员和社会关系</p>
        <p class="values">
          <el-col :span="24">
            <div class="values_label">
              <div>姓名</div>
              <div>与本人关系</div>
              <div>工作单位</div>
              <div>联系电话</div>
            </div>
          </el-col>
          <el-col :span="24">
            <ul
              class="values_value"
              v-for="(famliy, i) in eduStudentFamilyList"
              :key="i"
            >
              <li>
                <el-input v-model="famliy.memberName" clearable placeholder="姓名"></el-input>
              </li>
              <li>
                <el-select v-model="famliy.relateType" clearable>
                  <el-option
                    v-for="(item, index) in relateTypeSOptions"
                    :key="index"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </li>
              <li>
                <el-input v-model="famliy.workCompany" clearable  placeholder="工作单位"></el-input>
              </li>
              <li>
                <el-input v-model="famliy.contractNum" clearable placeholder="电话"></el-input>
              </li>
            </ul>
          </el-col>
        </p>
      </el-col>
    </el-row>

    <!-- 本人学历以及社会经历 -->
    <el-row class="stu_graduation_row">
      <el-col :span="24" class="item">
        <div class="labels">本人学历以及社会经历</div>
        <p class="values">
          <el-col :span="24">
            <div class="values_label values_labels_flex_2">
              <div>起止日期</div>
              <div>在何单位工作或学习（自中学开始填写）</div>
            </div>
          </el-col>
          <el-col :span="24">
            <ul
              class="values_value values_labels_flex_2"
              v-for="(expe, i) in experiences"
              :key="i"
            >
              <li>
                <el-date-picker
                  v-model="expe.date"
                  type="daterange"
                  format="yyyy-MM-dd"
                  value-format="yyyy-MM-dd"
                  range-separator="-"
                  start-placeholder="2000-01-01"
                  end-placeholder="2000-12-31"
                >
                </el-date-picker>
              </li>
              <li>
                <el-input v-model="expe.work" clearable  placeholder="工作单位或学校"></el-input>
              </li>
            </ul>
          </el-col>
        </p>
      </el-col>
    </el-row>

    <!-- 自我鉴定 -->
    <el-row class="stu_graduation_row">
      <el-col :span="24" class="item">
        <div class="labels">自我鉴定</div>
        <p class="values">
          <el-input
            type="textarea"
            v-model="selfIdentify"
            :maxlength="300"
            show-word-limit
            :autosize="{ minRows: 5, maxRows: 6 }" placeholder="300字以内"
          ></el-input>
        </p>
      </el-col>
    </el-row>
    <template v-if="queryRoot">
      <el-button v-if="btnShow === 0" @click="reset">重置</el-button>
      <el-button
        v-if="btnShow === 0"
        type="primary"
        :loading="btnLoading"
        @click="save"
        >保存</el-button
      >
    </template>
  </div>
</template>

<script>
import { QueryGraduationReport, AddGraduationReport } from "@/libs/api/student";
import { BASE_URL } from "@/libs/domain";
export default {
  data() {
    return {
      btnLoading: false,
      loading: false,
      // https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg
      imageUrl: "",
      // URL.createObjectURL(file.raw);
      basic: {
        stageName: "",
        profName: "",
        studentNo: "",
      },
      items: {
        imgUrl: "",
        name: "",
        sex: "",
        clan: "", // 民族
        birthday: "", // 出生日期
        certificateType: "", // 证件类型
        certificateNo: "", // 证件号码
        politicalStatus: "", // 政治面貌
        contactPhone: "", // 联系电话
        postCode: "", // 邮政编码
        contactAddr: "", // 通信地址
      },
      // 家庭主要成员和社会关系集合
      eduStudentFamilyList: [
        {
          memberName: "",
          relateType: "", // 与本人关系
          workCompany: "",
          contractNum: "",
        },
        {
          memberName: "",
          relateType: "",
          workCompany: "",
          contractNum: "",
        },
        {
          memberName: "",
          relateType: "",
          workCompany: "",
          contractNum: "",
        },
        {
          memberName: "",
          relateType: "",
          workCompany: "",
          contractNum: "",
        },
      ],

      // 本人学历以及社会经历集合
      experiences: [
        { date: [], work: "" },
        { date: [], work: "" },
        { date: [], work: "" },
        { date: [], work: "" },
        { date: [], work: "" },
        { date: [], work: "" },
      ],
      eduStudentExperiences: [],
      selfIdentify: "", // 自我鉴定
      nationalOptions: [{ label: "汉族", value: 0 }],
      cardTypeOptions: [{ label: "身份证", value: 0 }],
      politicalOptions: [
        { label: "共青团员", value: 0 },
        { label: "中共党员", value: 1 },
        { label: "群众", value: 2 },
        { label: "中共预备党员", value: 3 },
        { label: "民革会员", value: 4 },
        { label: "民盟会员", value: 5 },
        { label: "民建会员", value: 6 },
        { label: "民进会员", value: 7 },
        { label: "农工党党员", value: 8 },
        { label: "致公党党员", value: 9 },
        { label: "九三学社社员", value: 10 },
        { label: "台盟盟员", value: 11 },
        { label: "无党派民主人士", value: 12 },
      ],
      relateTypeSOptions: [
        { label: "父亲", value: 0 },
        { label: "母亲", value: 1 },
        { label: "兄弟", value: 2 },
        { label: "姐弟", value: 3 },
        { label: "兄妹", value: 4 },
        { label: "姐妹", value: 5 },
        { label: "其他", value: 6 },
      ], // 与本人关系 下拉框
      actionData: {},
      btnShow: 0,
    };
  },
  computed: {
    url() {
      return BASE_URL;
    },
    auth() {
      return this.$store.getters.getAuth;
    },
    user() {
      return this.$store.getters.getUser;
    },
    queryRoot() {
      return this.$sysPerKey("eduOnlineQa:queryGraduationReport");
    },
  },
  watch: {
    queryRoot(n) {
      if (n) {
        this.init();
      }
    },
  },
  mounted() {
    this.basic = this.user;
    this.queryRoot && this.init();
  },
  methods: {
    init() {
      this.loading = true;
      QueryGraduationReport()
        .then((res) => {
          const data = res.data;
          this.btnShow = res.data.delFlag;
          this.basic.stageName = data.stageName;
          this.basic.profName = data.profName;
          this.basic.studentNo = data.studentNo;
          // 头像
          this.imageUrl = data.headerImg || "";
          // this.dataURLtoFile(this.imageUrl, "图片.jpg");
          // 基本信息
          for (const key in data) {
            this.items[key] = data[key];
          }
          // 自我鉴定
          this.selfIdentify = data.selfIdentify;
          const fLen = data.eduStudentFamilyList.length;
          const eLen = data.eduStudentExperiences.length;
          // 家庭主要成员和社会关系

          this.eduStudentFamilyList = data.eduStudentFamilyList;
          if (fLen < 4) {
            for (let i = 0; i < 4 - fLen; i++) {
              this.eduStudentFamilyList.push({
                memberName: "",
                relateType: "",
                workCompany: "",
                contractNum: "",
              });
            }
          }
          // 本人学历以及社会经历
          if (eLen > 0) {
            this.experiences = [];
            data.eduStudentExperiences.forEach((item) => {
              this.experiences.push({
                date: [item.startTime || "", item.endTime || ""],
                work: item.experienceInfo,
              });
            });
            if (eLen < 6) {
              for (let i = 0; i < 6 - eLen; i++) {
                this.experiences.push({ date: [], work: "" });
              }
            }
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    reset() {
      this.items = {
        name: "",
        sex: "",
        clan: "", // 民族
        birthday: "", // 出生日期
        certificateType: "", // 证件类型
        certificateNo: "", // 证件号码
        politicalStatus: "", // 政治面貌
        contactPhone: "", // 联系电话
        postCode: "", // 邮政编码
        contactAddr: "", // 通信地址
      };
      this.eduStudentFamilyList = [
        {
          memberName: "",
          relateType: "",
          workCompany: "",
          contractNum: "",
        },
        {
          memberName: "",
          relateType: "",
          workCompany: "",
          contractNum: "",
        },
        {
          memberName: "",
          relateType: "",
          workCompany: "",
          contractNum: "",
        },
        {
          memberName: "",
          relateType: "",
          workCompany: "",
          contractNum: "",
        },
      ];
      this.experiences = [
        { date: "", work: "" },
        { date: "", work: "" },
        { date: "", work: "" },
        { date: "", work: "" },
        { date: "", work: "" },
        { date: "", work: "" },
      ];
      this.eduStudentExperiences = [];
      this.selfIdentify = "";
    },

    handleSuccess(res) {
      this.btnLoading = false;
      if (res.code === 2000) {
        this.$message.success("保存成功");
        return;
      }
      this.$message.error(res.message);
    },
    handleError(res) {
      this.btnLoading = false;
      this.$message.error("服务器错误，请稍后重试。");
    },
    onChange(file) {
      this.imageUrl = URL.createObjectURL(file.raw);
      this.items.imgUrl = file.raw;
      // this.imageUrl = file.raw;
    },
    dataURLtoFile(url, imageName, callback) {
      // imageName一定要带上后缀
      var blob = "";
      var xhr = new XMLHttpRequest();
      xhr.open("GET", url);
      xhr.setRequestHeader("Accept", "image/jpeg");
      xhr.responseType = "blob";
      xhr.onload = () => {
        if (xhr.status == 200) {
          blob = xhr.response;
          let imgFile = new File([blob], imageName, { type: "image/jpeg" });
          callback.call(this, imgFile);
        }
      };
      xhr.send();
    },
    save() {
      if (!this.imageUrl) return this.$message.error("请选择头像");
      if (!this.items.name) return this.$message.error("姓名不能为空");
      if (!this.items.sex) return this.$message.error("性别不能为空");
      if (!this.items.clan) return this.$message.error("民族不能为空");
      if (!this.items.postCode) return this.$message.error("邮政编码不能为空");
      // if (!this.items.birthday) return this.$message.error("出生日期不能为空");
      if (!this.items.certificateType && this.items.certificateType !== 0)
        return this.$message.error("证件类型不能为空");
      if (!this.items.certificateNo)
        return this.$message.error("证件号码不能为空");
      if (!this.items.politicalStatus && this.items.politicalStatus !== 0)
        return this.$message.error("政治面貌不能为空");
      if (!this.items.contactAddr)
        return this.$message.error("通信地址不能为空");
      if (this.eduStudentFamilyListBool(this.eduStudentFamilyList)) {
        return this.$message.error("家庭主要成员和社会关系至少录入完一行");
      }
      const experiencesFilter = this.experiences.filter(
        (f) => f.date && f.work
      );
      if (!experiencesFilter.length)
        return this.$message.error("本人学历以及社会经历至少录入完一行");
      experiencesFilter.forEach((item) => {
        this.eduStudentExperiences.push({
          startTime: item.date[0],
          endTime: item.date[1],
          experienceInfo: item.work,
        });
      });
      if (!this.selfIdentify) return this.$message.error("自我鉴定不能为空");
      this.btnLoading = true;
      let data = new FormData();
      data.append("file", this.items.imgUrl);
      data.append("clan", this.items.clan);
      data.append("name", this.items.name);
      data.append("politicalStatus", this.items.politicalStatus);
      data.append("sex", this.items.sex);
      data.append("certificateType", this.items.certificateType);
      data.append("contactAddr", this.items.contactAddr);
      data.append("contactPhone", this.items.contactPhone);
      data.append("postCode", this.items.postCode);
      data.append(
        "jsonFamily",
        JSON.stringify(this.eduStudentFamilyList.filter((f) => f.memberName))
      );
      data.append(
        "jsonExperience",
        JSON.stringify(
          this.eduStudentExperiences.filter((f) => f.experienceInfo)
        )
      );
      data.append("selfIdentify", this.selfIdentify);
      AddGraduationReport(data)
        .then((res) => {
          this.$message.success("保存成功");
          this.init();
          this.btnLoading = false;
        })
        .catch(() => {
          this.btnLoading = false;
        });
    },
    eduStudentFamilyListBool(eduStudentFamilyList){
      for (let i = 0 ; i < eduStudentFamilyList.length ; i ++) {
        if (eduStudentFamilyList[i].memberName && eduStudentFamilyList[i].workCompany && eduStudentFamilyList[i].contractNum){
          if (eduStudentFamilyList[i].relateType || eduStudentFamilyList[i].relateType === 0 ){
            return false;
          }
        }
      }
      return true;
    },
  },
};
</script>
